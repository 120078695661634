import { React } from '@/app'
import { View, Button, Link, useNavigation } from '@/components'
import { PropsOf, StylesOf } from '@codeleap/common'
import { Navigation, RoutePath } from '@/utils'
import { HeaderComposition } from '../../app/stylesheets/Header'

export type NavContentProps = {
  isMobile?: boolean
  variantStyles?: StylesOf<HeaderComposition>
}

type NavigateLink = {
  text: string
  route?: RoutePath
  to?: string
}

type ButtonProps = PropsOf<typeof Button>

const links: NavigateLink[] = [
  { text: 'Find your next run', route: 'Runs.Explore' },
  { text: 'Pace Calculator', route: 'Resources.PaceCalculator' },
  { text: "Britain's Best Runs By Area", route: 'Areas' },
]

const buttons: ButtonProps[] = [{
  debugName: 'Download Skamper button',
  text: 'Download Skamper for free',
  onPress: Navigation.General.navigateToStore,
  variants: ['alignSelfCenter'],
}]

export const NavContent = (props: NavContentProps) => {
  const { variantStyles } = props
  const { matchPath } = useNavigation()

  const renderLink = React.useCallback((link: NavigateLink) => {
    const isSelected = matchPath(Navigation.getRoutePath(link?.route))

    return (
      <Link
        key={'header-' + link?.text}
        text={link?.text}
        route={link?.route}
        to={link?.to}
        css={[variantStyles?.navItem, isSelected ? variantStyles['navItem:selected'] : {}]}
      />
    )
  }, [])

  return (
    <View component='nav' css={variantStyles.navContentWrapper}>
      {links?.map(renderLink)}
      {buttons?.map((button, index) => <Button key={`${button.text}: ${index}`} {...button} />)}
    </View>
  )
}
