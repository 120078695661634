import { variantProvider } from '@/app'
import { View, Text, Button, BottomDrawerContentProps } from '@/components'
import { CourseCriteria, CourseOrder } from '@/types'
import { CourseUtils } from '@/utils'
import { CourseFilterOption } from '@/utils/courses/filters'
import { TypeGuards, onUpdate } from '@codeleap/common'
import { ButtonProps, Slider } from '@codeleap/web'
import { useState } from 'react'

type Criteria = {
  key: CourseCriteria
  label: string
}

type Order = Partial<ButtonProps> & {
  key: CourseOrder
}

export const AllCoursesMobileFilters = (props: BottomDrawerContentProps) => {
  const { params, setParams, onApply, onClear, isOpen } = props

  const filterOptions = CourseUtils.getAllRunsFilterOptions(params)

  const orderButtons: Order[] = [
    {
      key: 'ascending',
      text: 'Sort Ascending',
      icon: 'arrow-up-2' as any,
    },
    {
      key: 'descending',
      text: 'Sort Descending',
      icon: 'arrow-down-2' as any,
    },
  ]

  const criterias: Criteria[] = [
    {
      key: 'paved',
      label: 'Paved',
    },
    {
      key: 'hilly',
      label: 'Hilly',
    },
    {
      key: 'ascent',
      label: 'Ascent',
    },
    {
      key: 'distance',
      label: 'Course Distance',
    },
    {
      key: 'nearest',
      label: 'Nearest',
    }
  ]

  const [sliderValues, setSliderValues] = useState(() => filterOptions.reduce((acc, option) => {
    const min = params?.[`min_${option.key}`]
    const max = params?.[`max_${option.key}`]
    acc[option.key] = [min || null, max || null]
    return acc
  }, {}))

  const [initialSliderValues] = useState(sliderValues)

  const initialCriteria = params.criteria || null
  const [selectedCriteria, setSelectedCriteria] = useState(initialCriteria)

  const initialOrder = params.order || null
  const [selectedOrder, setSelectedOrder] = useState(initialOrder)

  const [hasChanges, setHasChanges] = useState(false)

  const handleClear = () => {
    const resetSliderValues = filterOptions.reduce((acc, option) => {
      acc[option.key] = [null, null]
      return acc
    }, {})

    setSliderValues(resetSliderValues)
    setSelectedCriteria(null)
    setSelectedOrder(null)
    setParams({})
    setHasChanges(false)
    if (onClear) onClear()
  }

  function handleToggleTag(key: CourseCriteria) {
    setHasChanges(true)
    setSelectedCriteria(state => {
      return state === key ? null : key
    })
  }

  function handleToggleOrder(key: CourseOrder) {
    setHasChanges(true)
    setSelectedOrder(state => {
      return state === key ? null : key
    })
  }

  const handleSliderChange = (key: string, value: [number | null, number | null]) => {
    setHasChanges(true)
    setSliderValues(prev => ({
      ...prev,
      [key]: value,
    }))
  }

  const applyFilters = () => CourseUtils.applyMobileFilters({
    sliderValues,
    setHasChanges,
    setParams,
    onApply,
    additionalParams: {
      criteria: selectedCriteria,
      order: selectedOrder
    },
  })


  const BottomCriteriasComponent = () => {

    return (
      <View variants={['row', 'gap:1', 'wrap']}>
        {criterias?.map((criteria) => {
          const isSelected = selectedCriteria === criteria.key
          return (
            <Button
              key={criteria.key}
              onPress={() => handleToggleTag(criteria.key)}
              text={criteria.label}
              variants={['fitlerButton', isSelected && 'primary6']}
              debugName={`FilterCriterias:${criteria.key}`}
            />
          )
        })}
      </View>
    )
  }

  const BottomOrderComponent = () => {
    return (
      <View variants={['column', 'gap:1']}>
        {orderButtons.map(orderButton => {
          const isSelected = selectedOrder === orderButton.key
          return (
            <Button
              debugName={`button-${orderButton.key}`}
              text={orderButton.text}
              onPress={() => handleToggleOrder(orderButton.key)}
              key={orderButton.key}
              variants={['flex', 'large', 'minimal', 'sortFilter', isSelected && 'sortFilter:selected']}
              icon={orderButton.icon}
              style={styles.orderButton}
            />
          )
        })}
      </View>
    )
  }

  onUpdate(() => {
    if (!isOpen && hasChanges) {
      setSliderValues(initialSliderValues)
      setSelectedCriteria(initialCriteria)
      setSelectedOrder(initialOrder)
      setHasChanges(false)
    }
  }, [isOpen, hasChanges, initialSliderValues, initialCriteria])

  return (
    <View variants={['column', 'flex', 'marginBottom:12']}>
      {filterOptions.map((_filter) => {

        const filter = _filter as CourseFilterOption
        const min = filter?.min
        const max = filter?.max
        const defaultValue = filter?.defaultValue
        const suffix = filter?.suffix

        const _value = sliderValues[filter.key] || defaultValue

        const minValue = TypeGuards.isNil(_value[0]) ? min : _value[0]
        const maxValue = TypeGuards.isNil(_value[1]) ? max : _value[1]

        const minTrack = `${minValue}${suffix ? suffix : ''}`
        const maxTrack = `${maxValue}${suffix ? suffix : ''}`

        return (
          <Slider
            {...filter}
            debugName={`filter-${filter.key}`}
            key={filter.key}
            minStepsBetweenThumbs={0}
            value={[minValue, maxValue]}
            onValueChange={(value) => handleSliderChange(filter.key, value)}
            trackMarks={{
              [filter.min]: minTrack,
              [filter.max]: maxTrack,
            }}
          />
        )
      })}
      <View variants={['column', 'gap:2']}>
        <Text text={'Sort by'} />
        <BottomCriteriasComponent />
        <View variants={['fullWidth']} style={styles.separator} />
        <BottomOrderComponent />
      </View>
      <View variants={['gap:1', 'fixed', 'padding:2', 'fullWidth', 'center', 'bg:neutral1']} style={styles.buttonsWrapper}>
        <Button
          variants={['flex', 'primary6', 'border-radius-smallish']}
          debugName={'Clear Filters Explore'}
          onPress={() => handleClear()}
          text={'Clear'}
        />
        <Button
          variants={['flex', 'border-radius-smallish']}
          debugName={'Apply Filters Explore'}
          onPress={applyFilters}
          text={'Apply'}
        />
      </View>
    </View>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  buttonsWrapper: {
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: theme.zIndexes.activityIndicator + 1,
  },
  separator: {
    height: 1,
    backgroundColor: theme.colors.neutral3,
  },
  orderButton: {
    minHeight: theme.values.itemHeight.default,
  }
}), true)
