import { variantProvider } from '@/app'
import { View, CourseDistance, Button, Text, Image, CourseStats, GetAppButton } from '@/components'
import { Course } from '@/types'
import { CourseUtils, Navigation } from '@/utils'
import React from 'react'

export type CourseSimpleDetailsProps = {
  course: Course
}

export const CourseSimpleDetails = ({ course }: CourseSimpleDetailsProps) => (
  <View css={styles.wrapper}>
    {/* @ts-ignore */}
    <Image css={styles.image} source={CourseUtils.getCoverImage(course)} />

    <View variants={['column', 'padding:2']}>
      <View variants={['gap:2', 'marginBottom:2']}>
        <CourseDistance course={course} variants={['largeish']} />
        <Text text={course?.title} variants={['h3']} />
      </View>

      <CourseStats course={course} />

      <GetAppButton variants={['marginTop:2']} />

      {!!course?.description && (
        <View variants={['column', 'marginVertical:2']}>
          <Text text='Description' variants={['h5', 'neutral-8', 'marginBottom:1']} />
          <Text text={course?.description} />
        </View>
      )}

      <Button
        variants={['minimal']}
        debugName='CourseSimpleDetails:SeeMore'
        text='Load more'
        onPress={() => Navigation.Course.navigateToDetails(course)}
      />
    </View>

    <View variants={['separator']} />
  </View>
)

const styles = variantProvider.createComponentStyle((theme) => ({
  wrapper: { ...theme.presets.column, ...theme.presets.fullWidth, ...theme.spacing.marginBottom(4) },
  image: { ...theme.presets.fullWidth, height: 200, objectFit: 'cover' },
}), true)
