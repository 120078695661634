import { View, Text, Link } from '@/components'
import { RoutePath } from '@/utils'
import { StylesOf } from '@codeleap/common'
import { FooterComposition } from '../../../app/stylesheets/Footer'

export type FooterNavigateProps = {
  variantStyles?: StylesOf<FooterComposition>
}

type NavigateLink = {
  text: string
  route?: RoutePath
  to?: string
}

const links: Record<string, NavigateLink[]> = {
  information: [
    { text: 'Home', route: 'Home' },
    { text: 'Explore', route: 'Runs.Explore' },
    { text: 'Areas', route: 'Areas' },
    { text: 'Countries', route: 'Directory.Countries' },
    { text: 'States', route: 'Directory.States' },
    { text: 'Counties', route: 'Directory.Counties' },
    { text: 'Cities', route: 'Directory.Cities' },
    { text: 'Neighbourhoods', route: 'Directory.Neighbourhoods' },
    { text: 'Terms & Policy Privacy', route: 'Terms' },
  ],
  resources: [
    { text: 'Pace Calculator', route: 'Resources.PaceCalculator' },
  ],
}

function Column({ links, title = null, variantStyles }) {
  const renderLink = (link, index) => (
    <li>
      <Link
        key={index + 'footer-link'}
        route={link?.route}
        to={link?.to}
        openNewTab={!!link?.to}
        text={link?.text}
        css={variantStyles.navigateLink}
      />
    </li>
  )

  return (
    <View css={variantStyles.navigateColumn} component='ul' >
      {!!title && <Text css={variantStyles.navigateTitle} text={title} />}
      {links.map(renderLink)}
    </View>
  )
}

export const FooterNavigate = (props: FooterNavigateProps) => {
  const { variantStyles } = props

  return (
    <View css={variantStyles.navigateWrapper} component='nav'>
      <Column
        variantStyles={variantStyles}
        links={links?.information}
        title='Information'
      />

      <Column
        variantStyles={variantStyles}
        links={links?.resources}
        title='Resources'
      />
    </View>
  )
}
