import {
  Course,
  CourseCategory,
  CourseFilters,
  CourseMedia,
  CourseStat,
  CouseTrack,
  Location,
  Rating,
  RatingOverview,
} from '@/types'
import { Units } from '../units'
import { SpatialUtils } from '../spatial'
import { UserLocationUtils } from '../UserLocation'
import { AppImages, Theme } from '@/app'
import { Results } from '../results'
import { Maps } from '../maps/courses'
import { TypeGuards, onMount, useState } from '@codeleap/common'
import { applyMobileFilters, clearAllFilters, clearFilters, getAllRunsFilterOptions, getExploreFilterOptions } from './filters'
import { removeLeadingSlash } from '../misc'
import { getCoursePathname } from 'gatsby-utils'
import { SEOProps } from '@/components'

const getStartingPoint = (course: Course) => {
  if (!!course?.waypoints?.[0]) return course?.waypoints?.[0]
  if (!!course?.tracks?.[0]?.points?.[0]) {
    return course?.tracks?.[0]?.points?.[0]
  }

  return null
}

const getOriginalPoints = (course?: Course) => {
  if (!course?.tracks) return []

  const originalPoints = course?.tracks
    ?.map((t) => t.points.filter((pt) => pt.original))
    .flat()

  return originalPoints
}

const distanceFromStart = (coords: Location, course: Course) => {
  const startingPoint = getStartingPoint(course)
  return SpatialUtils.distanceTo(startingPoint, coords)
}

const useDistanceFromStart = (course: Course) => {
  const { location } = UserLocationUtils.useLocation()
  const rawDistance = distanceFromStart(location, course)
  const previewDistance = Units.convert({
    value: rawDistance,
    from: 'm',
    to: 'km',
    toFixed: 1,
    withSuffix: true,
  })

  return {
    rawDistance,
    previewDistance,
  }
}

type GetPreviewDistanceProps = {
  course: Course
  withSuffix?: boolean
}

const getPreviewDistance = ({ course, withSuffix }: GetPreviewDistanceProps) => Units.convert({
  value: course?.distance_valid,
  withSuffix,
  from: 'm',
  to: 'km',
  toFixed: 1,
})

const isTrackDashed = (trackName: CouseTrack['name']) => trackName.includes('dashed')

const courseIcons = {
  SL: 'single-loop',
  ML: 'multiple-laps',
  LP: 'lollipop',
  OR: 'out-and-return',
  AL: 'adjacent-loops',
  PP: 'point-to-point',
}

const courseTypeText = {
  SL: 'Single loop - circle',
  ML: 'Multiple loops',
  LP: 'Lollipop',
  OR: 'Out and return',
  AL: 'Adjacent loops',
  PP: 'Point to point',
}

function getStartAndEndCps(course: Course) {
  const checkpoints = course?.waypoints
  let end = checkpoints && checkpoints[0]
  let start = checkpoints && checkpoints[0]

  checkpoints?.forEach((cp) => {
    if (cp?.real_order > end?.real_order) {
      end = cp
    }
    if (cp?.real_order < start?.real_order) {
      start = cp
    }
  })

  return { end, start }
}

const formatRuntime = (course: Course) => Results.convertRunTimes({ seconds: course?.runtime })

const getStatValues = (course: Course, stat: CourseStat) => {
  switch (stat) {
    case 'runtime':
      return {
        value: `${CourseUtils.formatRuntime(course)}`,
        label: 'Time',
        icon: 'clock',
      }
    case 'ascent':
      return {
        value: `${course?.ascent} m`,
        label: 'Ascent',
        icon: 'arrow-up',
      }
    case 'paved':
      return {
        value: `${course?.paved}%`,
        label: 'Paved',
        icon: 'road',
      }
    case 'hilly':
      return {
        value: `${course?.hilly}/5`,
        label: 'Hilly',
        icon: 'terrain',
      }
    case 'waypoints':
      return {
        value: course?.waypoints?.length - 1,
        label: 'CPs',
        icon: 'map-pin',
      }
  }
}

const getCoverImage = (
  course: Course,
  options = {
    attachSiteURL: false,
  },
): CourseMedia['file'] => {
  const isArray = TypeGuards.isArray(course?.media)
  const mediaArr = course?.media as CourseMedia[]
  const media = course?.media as CourseMedia
  const hasNoMedia = isArray ? mediaArr.length === 0 : !media
  if (hasNoMedia) {
    return options?.attachSiteURL
      ? `${process.env.GATSBY_SITE_URL}${removeLeadingSlash(
        AppImages.CoursePhotoExample,
      )}`
      : AppImages.CoursePhotoExample
  }
  return isArray ? mediaArr?.[0]?.file : media.file
}

const courseFilters: (keyof CourseFilters)[] = [
  'lat',
  'lng',
  'max_distance',
  'min_distance',
  'max_elevation',
  'min_elevation',
  'max_paved',
  'min_paved',
  'max_hilly',
  'min_hilly',
  'tags',
  'voice_directions',
  'order',
  'criteria',
  'min_ascent',
  'max_ascent'
]

const getFiltersByParams = (params?: CourseFilters) => {
  if (!params) return {}

  const filters = {}

  Object.entries(params).map(([key, value]) => {
    if (!value || !courseFilters.includes(key as keyof CourseFilters)) {
      return null
    }

    if (key === 'min_distance' || key === 'max_distance') {
      filters[key] = Units.convert({
        value: Number(value),
        from: 'km',
        to: 'm',
        toFixed: 0,
      })
      return
    }

    if (key === 'tags' && Array.isArray(value)) {
      filters[key] = value.filter((tag) => tag !== null && tag !== '')
      return
    }

    if (key === 'voice_directions') {
      filters[key] = value === 'true'
      return
    }

    filters[key] = value
  })

  return filters
}

type SimilarCourseProps = {
  course: Course
  paved_tolerance?: number
  min_distance_tolerance?: number
  max_distance_tolerance?: number
}

const getSimilarCourseParams = (props: SimilarCourseProps) => {
  const {
    course,
    paved_tolerance = 0.15,
    min_distance_tolerance = 0.8,
    max_distance_tolerance = 1.2,
  } = props

  const min_hilly = Number(course?.hilly)
  const max_hilly = Number(course?.hilly)

  const min_paved =
    Number(course?.paved) - Number(course?.paved) * paved_tolerance
  const max_paved =
    Number(course?.paved) + Number(course?.paved) * paved_tolerance

  const min_distance = course?.distance_total * min_distance_tolerance
  const max_distance = course?.distance_total * max_distance_tolerance

  return {
    min_hilly: TypeGuards.isNumber(min_hilly) ? min_hilly : null,
    max_hilly: TypeGuards.isNumber(max_hilly) ? max_hilly : null,
    min_paved: TypeGuards.isNumber(min_paved) ? min_paved : null,
    max_paved: TypeGuards.isNumber(max_paved) ? max_paved : null,
    min_distance: TypeGuards.isNumber(min_distance) ? min_distance : null,
    max_distance: TypeGuards.isNumber(max_distance) ? max_distance : null,
  }
}
const CourseCategories: CourseCategory[] = [
  {
    type: 'hilly',
    label: 'Hilly',
    filters: { min_hilly: 4 },
    image: AppImages.HillyCategory,
  },
  {
    type: 'flat',
    label: 'Flat',
    filters: { max_hilly: 0 },
    image: AppImages.FlatCategory,
  },
  {
    type: 'long',
    label: 'Long runs',
    filters: { min_distance: 12 },
    image: AppImages.LongCategory,
  },
  {
    type: 'short',
    label: 'Short runs',
    filters: { max_distance: 6 },
    image: AppImages.ShortCategory,
  },
  {
    type: 'paved',
    label: 'Paved runs',
    filters: { min_paved: 100 },
    image: AppImages.PavedCategory,
  },
  {
    type: 'trail',
    label: 'Trail runs',
    filters: { max_paved: 30 },
    image: AppImages.TrailCategory,
  },
  {
    type: 'mountain',
    label: 'Mountain',
    filters: { min_elevation: 450 },
    image: AppImages.MountainCategory,
  },
  {
    type: '10ks',
    label: '10km runs',
    filters: { min_distance: 9.5, max_distance: 10.5 },
    image: AppImages.TenKmCategory,
  },
]

const useAscentChartWidth = (padding) => {
  const [ascentChartWidth, setAscentChartWidth] = useState(0)

  onMount(() => {
    const calculateWidth = () => {
      if (typeof window !== 'undefined') {
        const width =
          (window.innerWidth - (padding * 2 + Theme.spacing.value(20))) / 2
        setAscentChartWidth(width)
      }
    }

    calculateWidth()
    window.addEventListener('resize', calculateWidth)

    return () => window.removeEventListener('resize', calculateWidth)
  })

  return ascentChartWidth
}

const isReadyToRenderOnMap = (course: Course) => {
  return !!course?.original_points?.length && course?.waypoints?.length > 1
}

const getImageAlt = (course: Course, imageNumber = 1) => `${course.title} - Photo ${imageNumber}`

const calculateRatingScore = (
  stars: { [key: string]: number },
  reviews: number,
) => {
  let sum = 0
  if (!!stars) {
    for (const [score, qtd] of Object.entries(stars)) {
      sum += Number(score) * qtd
    }
  }
  return reviews === 0 ? reviews : sum / reviews
}

const getPathName = (course: Course) => {
  return getCoursePathname(course)
}

function getSEORatingItem(rating: Rating, overview: RatingOverview) {
  return {
    '@type': 'Review',
    'author': {
      '@type': 'Person',
      'name': rating?.profile?.full_name,
    },
    'datePublished': rating?.created_datetime,
    'reviewBody': rating?.feedback,
    'reviewRating': {
      '@type': 'Rating',
      'ratingValue': rating?.stars,
      'bestRating': overview?.maxNote,
      'worstRating': overview?.minNote,
    },
  }
}

function getSEORatings(ratings: Rating[], overview: RatingOverview) {
  if (TypeGuards.isNil(ratings)) return null
  return JSON.stringify(ratings.map(rating => getSEORatingItem(rating, overview)))
}

function getSEOAllCourses(): Partial<SEOProps> {
  return {
    title: 'All runs',
    description: 'Find all runs available on Skamper.',
    pathname: '/runs',
  }
}

function getRatingOverview(stars: { [key: string]: number }) {
  if (TypeGuards.isNil(stars)) return
  const reviewsQtd = Object.values(stars).reduce((accumulator: number, currentValue: number) => accumulator + currentValue, 0) as number
  const minNote = Math.min(...Object.keys(stars).map(key => parseInt(key)))
  const maxNote = Math.max(...Object.keys(stars).map(key => parseInt(key)))
  const score = calculateRatingScore(stars, reviewsQtd)

  return {
    reviewsQtd,
    minNote,
    maxNote,
    score,
  }
}

export const CourseUtils = {
  getSEOAllCourses,
  getImageAlt,
  getStatValues,
  formatRuntime,
  getOriginalPoints,
  getPreviewDistance,
  getStartingPoint,
  distanceFromStart,
  useDistanceFromStart,
  isTrackDashed,
  getFiltersByParams,
  getCoverImage,
  getStartAndEndCps,
  getSimilarCourseParams,
  useAscentChartWidth,
  getExploreFilterOptions,
  courseIcons,
  courseTypeText,
  courseFilters,
  Maps,
  CourseCategories,
  isReadyToRenderOnMap,
  clearFilters,
  clearAllFilters,
  calculateRatingScore,
  getPathName,
  getRatingOverview,
  getSEORatings,
  getSEORatingItem,
  getAllRunsFilterOptions,
  applyMobileFilters,
}
