import { Theme } from '@/app'
import { Course } from '@/types'
import { Navigation } from '@/utils'
import { PropsOf } from '@codeleap/common'
import { useCallback, useRef } from 'react'
import { EmptyPlaceholder } from '@codeleap/web'
import { ActivityIndicator, CourseCardProps, View, CourseCard, Grid, Button } from '@/components'

type GridProps = Omit<PropsOf<typeof Grid>, 'data' | 'style' | 'placeholder'>

export type CourseGridProps = Partial<GridProps> & {
  courses: Course[]
  cardVariants?: CourseCardProps['variants']
  onPress?: (Course: Course) => void
  style?: PropsOf<typeof View>
}

export const CourseGrid = ({
  courses,
  cardVariants,
  onPress,
  numColumns = 2,
  rowItemsSpacing = Theme.spacing.value(2),
  columnItemsSpacing = Theme.spacing.value(2),
  style,
  ...rest }: CourseGridProps) => {

  const gridRef = useRef(null)

  const renderItem = useCallback(({ item }) => {

    return (
      <CourseCard
        course={item}
        variants={['wide', 'fullWidth', 'grid:wide']}
        onPress={() => Navigation.Course.navigateToDetails(item)}
      />
    )
  }, [cardVariants, onPress])

  if (rest.isLoading) {
    return (
      <View variants={['justifyCenter', 'flex', 'alignCenter']}>
        <ActivityIndicator debugName='CourseList:Loading' />
      </View>)
  }

  if (!courses?.length) {
    return (
      <EmptyPlaceholder
        title='No Courses'
        description='There are no Courses on this location'
        variants={['compact', 'icon:size4'] as any}
      />
    )
  }

  const hasNextPage = !!rest.hasNextPage && !rest?.isFetchingNextPage

  return (
    <View ref={gridRef} variants={['column']}>
      <Grid
        data={courses as unknown as string[]}
        debugName='CourseGrid'
        numColumns={numColumns}
        rowItemsSpacing={rowItemsSpacing}
        columnItemsSpacing={columnItemsSpacing}
        renderItem={renderItem}
        isLoading={rest?.isLoading}
        ListLoadingIndicatorComponent={() => (
          <ActivityIndicator debugName='Course List Loader' variants={['fullWidth']} />
        )}
        isFetchingNextPage={rest?.isFetchingNextPage}
      />

      {!!hasNextPage && (
        <Button
          onPress={rest?.fetchNextPage}
          debugName='CourseCrid:Load more'
          text='Load more'
          variants={['minimal']}
        />)}
    </View>
  )

}

