import { EmotionJSX } from '@emotion/react/types/jsx-namespace'
import { ExploreMobileFilters } from '../Courses/ExploreMap/components'
import { BottomDrawerBase, BottomDrawerBaseProps } from './Base'
import { View, Text, ActionIcon } from '@/components'

export type BottomDrawerContentProps = {
  params: Record<string, string>
  setParams: React.Dispatch<React.SetStateAction<Record<string, string>>>
  onApply?: () => void
  onClear?: () => void
  isOpen?: boolean
}

export type FiltersBottomDrawerProps = {
  open: boolean
  onDismiss: () => void
  params: Record<string, string>
  setParams: React.Dispatch<React.SetStateAction<Record<string, string>>>
  onApply?: () => void
  content?: (props: BottomDrawerContentProps) => EmotionJSX.Element
  bottomDrawerProps?: Partial<BottomDrawerBaseProps>
}

export const FiltersBottomDrawer = (props: FiltersBottomDrawerProps) => {

  const { open, onDismiss, content: Content = ExploreMobileFilters, bottomDrawerProps = {}, ...rest } = props

  return (
    <BottomDrawerBase
      open={open}
      onDismiss={onDismiss}
      {...bottomDrawerProps}
      {...rest}
    >
      <View variants={['justifySpaceBetween', 'marginBottom:2']}>
        <Text variants={['bold']} text={'Filters'} />
        <ActionIcon
          icon={'close'}
          variants={['normalize', 'neutral9']}
          debugName='Close Filters Drawer'
          onPress={onDismiss}
        />
      </View>
      <Content
        onClear={onDismiss}
        onApply={onDismiss}
        isOpen={open}
        {...rest}
      />
    </BottomDrawerBase>
  )
}
