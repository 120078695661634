import { Theme, variantProvider } from '@/app'
import {
  View,
  Text,
  PlacesAutoComplete,
  CourseFilters,
  CourseFiltersProps,
  Tooltip,
  Button,
  CoursesSortOptions,
  FiltersBottomDrawer,
  ActionIcon,
  AllCoursesMobileFilters,
} from '@/components'
import { SearchPrediction, ShortCoords } from '@/types'
import {
  AutocompleteUtils,
  CourseUtils,
  UserLocationUtils,
  useClickClosest,
  useIsMobile,
  useIsTablet,
} from '@/utils'
import { CustomFilterProps } from '@/utils/courses/filters'
import { PropsOf, TypeGuards, onUpdate, useBooleanToggle, useState } from '@codeleap/common'
import React from 'react'

type AllCoursesFiltersProps = PropsOf<typeof View> & {
  courseFiltersProps: CourseFiltersProps
  totalCourses: number
}

const SortyByButton = (props: CustomFilterProps) => {
  const { filterVisible, setFilterVisible, params, setParams } = props
  const visible = filterVisible === 'sort_by'
  const id = 'filter-button-sorty_by'
  const [internalFilters, setInternalFilters] = useState({ order: params?.order || null, criteria: params?.criteria || null })
  const hasOrder = !TypeGuards.isNil(params?.order)
  const hasCriteria = !TypeGuards.isNil(params?.criteria)
  const hasFilter = hasOrder || hasCriteria
  const isApplyDisabled = TypeGuards.isNil(internalFilters.order) || TypeGuards.isNil(internalFilters.criteria)

  function toggle() {
    visible ? setFilterVisible(null) : setFilterVisible('sort_by')
  }

  function onApply() {
    setParams(state => {
      return { ...state, ...internalFilters }
    })
    toggle()
  }

  function onClear() {
    resetInternalFilter()
    setParams(state => {
      return { ...state, order: null, criteria: null }
    })
    toggle()
  }

  function resetInternalFilter() {
    setInternalFilters({ order: null, criteria: null })
  }

  useClickClosest({
    elementIds: [id, `filter-content-sorty_by`],
    onNotClickClose: toggle,
    enabled: visible,
  })

  onUpdate(() => {
    if (TypeGuards.isNil(params) && !visible) {
      resetInternalFilter()
    }
  }, [params])

  return (
    <Tooltip
      /* @ts-ignore */
      id={id}
      debugName='Sorty By filter tooltip'
      open={visible}
      toggle={toggle}
      openOnHover={false}
      closeOnClickOutside
      side='bottom'
      variants={['bare', 'overlap', 'small'] as any}
      content={
        <View css={styles.filterWrapper} id={`filter-content-sorty_by`}>
          <CoursesSortOptions
            internalFilters={internalFilters}
            setInternalFilters={setInternalFilters}
          />
          <View css={styles.filterFooter}>
            <Button
              text='Clear'
              debugName='Filter:Clear'
              variants={['flex', 'primary6']}
              onPress={onClear}
              css={styles.clearButton}
            />

            <Button
              variants={['flex']}
              debugName='Filter:Apply'
              text={`Apply`}
              onPress={onApply}
              css={styles.seeResultsButton}
              disabled={isApplyDisabled}
            />
          </View>
        </View>
      }
    >
      <Button
        debugName='Sorty By filter button'
        rightIcon='arrow-down'
        text='Sorty by'
        variants={[visible ? 'fitlerButton:selected' : 'fitlerButton']}
        style={{ flex: `0 0 auto` }}
        onPress={toggle}
        icon={hasFilter ? 'badge' : null}
      />
    </Tooltip>
  )
}

export const AllCoursesFilters = (props: AllCoursesFiltersProps) => {
  const { courseFiltersProps, totalCourses, ...otherProps } = props
  const search = courseFiltersProps?.params?.search
  const [bottomFilters, toggleBottomFilters] = useBooleanToggle(false)
  const { location: userLocation } = UserLocationUtils.useCurrentLocation()
  const placeTitle = courseFiltersProps.params?.search

  const isMobile = useIsMobile()
  const isTablet = useIsTablet()

  const [inputValue, setInputValue] = useState(search)

  const hasFilters = CourseUtils.courseFilters.some(key => {
    return (key !== 'lat' && key !== 'lng') && (courseFiltersProps.params && courseFiltersProps.params[key] !== undefined)
  })

  function onItemPress(place: SearchPrediction) {
    const placeTitle = AutocompleteUtils.getPlaceTitle(place)
    setPosAndSearch({ position: place?.coords, search: placeTitle })

  }

  function setSearch(search: string) {
    courseFiltersProps.setParams(state => ({ ...state, search }))
  }

  const filterOptions = [
    ...CourseUtils.getAllRunsFilterOptions(courseFiltersProps.params),
    {
      key: 'sort_by',
      customFilter: SortyByButton,
    },
  ]

  function setPosAndSearch({ position, search = '' }: { position: ShortCoords; search: string }) {
    courseFiltersProps.setParams(state => ({
      ...state,
      search,
      lat: position ? String(position.lat) : null,
      lng: position ? String(position.lng) : null,
    }))
  }

  return (
    <>
      <View component='section' {...otherProps}>
        <View component='section' variants={['column', 'flex', 'gap:3']}>
          <View
            component='section'
            variants={['alignCenter', 'gap:5']}
            responsiveVariants={{
              mobile: ['gap:2'],
            }}
          >
            <View variants={['flex']} >
              <PlacesAutoComplete
                searchText={search}
                onChangeSearch={setSearch}
                onItemPress={onItemPress}
                origin={userLocation}
                variants={['mid', 'border-radius-input:smallish']}
                defautlValue={search}
                value={inputValue}
                setValue={setInputValue}
                placeholder='Search a location'
              />
            </View>

            {!isTablet ? (
              <View style={styles.filtersWrapper}>
                <CourseFilters
                  {...courseFiltersProps}
                  filterOptions={filterOptions}
                  styles={{
                    outerWrapper: { minWidth: 'auto', padding: 0 },
                  }}
                />
              </View>
            ) : (
              <ActionIcon
                onPress={() => toggleBottomFilters()}
                debugName={'All Runs:Filters'}
                icon={'filter'}
                variants={['medium', 'primary3', 'neutral2', hasFilters && 'primary1']}
                styles={{
                  touchableWrapper: { borderRadius: Theme.spacing.value(2) },
                }}
              />
            )}
          </View>

          {!isTablet ? (
            <View component='section'>
              {courseFiltersProps?.isLoading ? (
                <Text text={'Loading...'} variants={['p3', 'color:neutral9', 'marginLeft:1']} />
              ) : (
                <View>
                  <Text text={`${totalCourses} results`} variants={['p3', 'color:neutral9', 'marginLeft:1', 'noWrap']} />
                  {!!placeTitle && <Text text={`for "${placeTitle}"`} variants={['p3', 'color:neutral7', 'marginLeft:0.5', 'noWrap']} />}
                </View>
              )}
            </View>
          ) : null}
        </View>
      </View>
      <FiltersBottomDrawer
        open={bottomFilters}
        params={courseFiltersProps.params}
        setParams={courseFiltersProps.setParams}
        onDismiss={() => toggleBottomFilters()}
        content={AllCoursesMobileFilters}
        bottomDrawerProps={{
          variants: [!isMobile && 'innerWrapperHeight:auto'],
        }}
      />
    </>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  filterWrapper: {
    ...theme.presets.relative,
    ...theme.presets.elevated,
    borderRadius: theme.borderRadius.medium,
    ...theme.spacing.padding(2),
    ...theme.presets.column,
    width: theme.spacing.value(30),
    backgroundColor: theme.colors.neutral1,
    bottom: theme.spacing.value(1),

    [theme.media.down('mobile')]: {
      width: `90vw`,
    },
  },
  filterFooter: {
    ...theme.spacing.marginTop(2),
    gap: theme.spacing.value(2),

    [theme.media.down('mobile')]: {
      ...theme.presets.column,
    },
  },
  seeResultsButton: {
    minHeight: theme.values.itemHeight.default,
    borderRadius: theme.borderRadius.smallish,
    [theme.media.down('mobile')]: {
      order: 1,
    },
  },
  clearButton: {
    minHeight: theme.values.itemHeight.default,
    borderRadius: theme.borderRadius.smallish,
    [theme.media.down('mobile')]: {
      order: 2,
    },
  },
  filtersWrapper: {
    maxHeight: 33,
  },
}), true)
