import { React, useBreakpointMatch, variantProvider } from '@/app'
import { COURSE_CARD_CONSTANTS } from '@/app/stylesheets/CourseCard'
import {
  View,
  Text,
  Page,
  CourseMap,
  CourseAscentChart,
  ActivityIndicator,
  CourseDistance,
  CourseStats,
  GetAppButton,
  CourseTags,
  Touchable,
  CourseBannerSlider,
  CourseCarousel,
  CourseCarouselProps,
  CourseDisaclaimers,
  CourseRatings,
  CourseList,
  CourseListProps,
  CenterWrapper,
} from '@/components'
import { APIClient } from '@/services'
import { Course } from '@/types'
import {
  CourseUtils,
  Navigation,
  useFlatlistProps, useIsMobile,
  useIsTablet,
  useMaxContentWidth,
  useMediaQueryDown,
} from '@/utils'
import { HTML } from '../HTML'

type CourseSliderComponentProps = {
  courses: Course[]
  title: string
  isLoading: boolean
  type: 'similar' | 'nearby'
  listProps?: ReturnType<typeof useFlatlistProps>
} & Partial<CourseCarouselProps>

const CourseSliderComponent = (props: CourseSliderComponentProps) => {

  const { courses, title, isLoading, type, carouselOptions, listProps } = props

  const isMobile = useIsMobile()
  const isTablet = useIsTablet()

  const slidesToShow = useBreakpointMatch({
    mobile: null,
    tabletSmall: 2,
    laptop: 3,
    desktopLarge: 4,
    desktopHuge: 5,
  })

  const { data, ...list } = listProps || {}

  const viewAllNavigate = () => {
    let params = {}
    if (type === 'similar') {
      params = CourseUtils.getSimilarCourseParams({ course: courses[0] })
    }
    Navigation.Course.navigateToExplore({
      ...params,
    })
  }

  return (
    <>

      <View
        variants={['justifySpaceBetween', 'alignCenter']}
        responsiveVariants={{
          mobile: ['paddingHorizontal:2'],
        }}
        component='header'
      >

        <Text
          variants={[`h2`, 'marginBottom:3']}
          responsiveVariants={{
            tabletSmall: ['h4'],
          }}
          text={title}
          component='h2'
        />

        {isMobile ? (
          <Touchable onPress={viewAllNavigate} debugName='View all press - Navigate to map page'>
            <Text variants={[`p3`, 'color:primary3', 'marginBottom:3']} text={'View all'} />
          </Touchable>
        ) : null}

      </View>

      {isLoading ? (
        <View variants={['flex', 'center']} style={styles.loadingIndicatorWrapper}>
          <ActivityIndicator debugName='Course slider loading indicator' />
        </View>
      ) : (
        <View variants={['fullWidth', isTablet && 'block']}>
          {isTablet ? (
            <CourseCarousel
              courses={courses}
              defaultCardWidth={COURSE_CARD_CONSTANTS.LARGE.WIDTH}
              smallCardWidth={COURSE_CARD_CONSTANTS.SQUARE.WIDTH.MOBILE}
              courseCardProps={{
                variants: isTablet ? ['square'] : ['large'],
                onPress: (item) => Navigation.Course.navigateToDetails(item),
              }}
              slidesToShow={slidesToShow}
              carouselOptions={{
                dragFree: true,
                arrows: !isTablet,
                enablePagination: true,
                ...carouselOptions,
              }}
            />
          ) : (
            <CourseList
              {...list as unknown as CourseListProps}
              courses={courses}
              debugName='teste'
              onPress={(item) => Navigation.Course.navigateToDetails(item)}
              cardVariants={['large', 'bottomCardContentVisible', 'hideCardContent', 'fullWidth']}
            />
          )}
        </View>
      )}
    </>
  )
}

export type CoursePageProps = {
  course: Course
}

const _CoursePage = ({ course: _course }: CoursePageProps) => {
  const { data: fullCourse } = APIClient.Courses.coursesManager.useRetrieve({
    id: _course?.id,
    queryOptions: {
      refetchOnWindowFocus: false,
      enabled: !!_course?.id && _course?.original_points?.length === 0,
    },
  },
  )

  const course = fullCourse || _course

  const startingPoint = CourseUtils.getStartingPoint(course) || {}

  const { courses } = APIClient.Courses.useCourses({
    ...startingPoint,
    enabled: !!startingPoint,
  })

  const nearbyCourses = courses?.items?.filter(c => c.id !== Number(course?.id))
  const coursesList = useFlatlistProps(courses, {
    noMoreItemsText: null,
  })

  const { padding } = useMaxContentWidth()
  const isTablet = useIsTablet()
  const isBelowTablet = useMediaQueryDown('tablet')

  const ascentChartWidth = CourseUtils.useAscentChartWidth(padding)
  const ascentChartHeight = isTablet ? 90 : 240

  return (
    <Page
      SEOProps={{
        title: course?.title,
        description: course?.description,
        pathname: `run/${course?.location_name_slug}/${course?.slug}`,
        image: CourseUtils.getCoverImage(course, { attachSiteURL: true }),
      }}
      pageTitle={course?.title}
      showFooter={false}
      responsiveWrapper
      HeaderComponent={<CourseBannerSlider course={course} showMap={true} showBreadcrumbs />}
      centerContent={false}
    >
      <CenterWrapper component='section'>
        <View
          variants={['marginTop:5', 'marginBottom:10', 'fullWidth']}
          responsiveVariants={{
            tabletSmall: ['marginTop:2', 'marginBottom:4'],
          }}
        >
          <View variants={['flex', 'column']}>
            <View component='header'>
              <CourseDistance
                course={course}
                variants={['large']}
                responsiveVariants={{ tabletSmall: ['largeish'] } as any}
              />
              <Text
                variants={[`h1`, 'marginLeft:2']}
                text={course?.title}
                responsiveVariants={{ tabletSmall: ['h3'] }}
                component='h1'
              />
            </View>

            <CourseStats
              course={course}
              variants={['marginVertical:7'] as any}
              responsiveVariants={{ tabletSmall: ['marginVertical:2'] } as any}
            />

            <GetAppButton />

          </View>

          {!isBelowTablet && (
            <View variants={['flex', 'marginLeft:10']}>
              <CourseMap
                course={course}
                style={styles.map}
                expandable
              />
            </View>)}
        </View>
      </CenterWrapper>

      <CenterWrapper component='section'>
        <View
          variants={['marginBottom:10', 'gap:10', 'fullWidth']}
          responsiveVariants={{
            tabletSmall: ['column-reverse', 'marginBottom:4', 'gap:4'],
          }}
        >

          <View variants={['flex', 'column', 'fullWidth']} css={styles.contentWrapper}>
            {course?.html_description ? (
              <HTML htmlContent={course.html_description} />
            ) : (
              <>
                <Text variants={[`h5`, 'color:neutral8']} text={`Description`} component='h3' />
                <Text variants={['p1', 'marginTop:1']} text={course?.description} />
              </>
            )}

            <CourseDisaclaimers course={course} />

            <View variants={['marginTop:2', 'wrap', 'gap:1']} component='ul'>
              {/* @ts-ignore */}
              <CourseTags course={course} />
            </View>
          </View>

          <View
            variants={['flex', 'justifyCenter', 'marginRight:-4']}
            responsiveVariants={{
              tabletSmall: ['marginBottom:4', 'marginLeft:0'],
            }}
          >
            <CourseAscentChart
              course={course}
              width={ascentChartWidth}
              height={ascentChartHeight}
            />
          </View>

        </View>
      </CenterWrapper>

      <CenterWrapper variants={['noMobilePadding']} component='section'>
        <View
          variants={['fullWidth']}
          style={styles.bottomWrapper}
        >
          <View variants={['column']} style={styles.coursesCarouselWrapper} component='aside'>
            <CourseSliderComponent
              courses={nearbyCourses}
              title={'More runs nearby'}
              type={'nearby'}
              isLoading={courses?.list?.query?.isLoading}
              carouselOptions={{
                onEndReached: courses?.list?.getNextPage,
                hasNextPage: courses?.list?.query?.hasNextPage,
              }}
              listProps={coursesList}
            />
          </View>

          <CourseRatings course={course?.id} variants={['marginBottom:5', 'fullWidth']} style={styles.ratingsWrapper} />

        </View>
      </CenterWrapper>

    </Page>
  )
}

export const CoursePage = (props: CoursePageProps) => {
  if (!props?.course) return <ActivityIndicator debugName='Course Page Loader' />

  return (<_CoursePage {...props} />)
}

const RATING_IMAGE_WIDTH = 180
const RATING_IMAGE_HEIGHT = 120
const LOADING_MIN_HEIGHT = 200

const styles = variantProvider.createComponentStyle((theme) => ({
  loadingIndicatorWrapper: {
    minHeight: LOADING_MIN_HEIGHT,
  },
  map: {
    borderRadius: theme.borderRadius.medium,
    overflow: 'hidden',
    width: '100%',
  },
  image: {
    width: RATING_IMAGE_WIDTH,
    height: RATING_IMAGE_HEIGHT,
  },
  bottomWrapper: {
    ...theme.presets.flex,
    ...theme.presets.row,
    ...theme.spacing.gap(10),

    [theme.media.down('tabletSmall')]: {
      ...theme.presets.column,
      ...theme.spacing.gap(4),
    },
  },
  coursesCarouselWrapper: {
    order: 1,
    minWidth: 320,

    [theme.media.down('tabletSmall')]: {
      ...theme.presets.fullWidth,
      order: 0,
    },

  },
  contentWrapper: {
    width: '45%',

    [theme.media.down('tabletSmall')]: {
      ...theme.presets.fullWidth,
    },
  },
  ratingsWrapper: {
    order: 0,
    [theme.media.down('mobile')]: {
      ...theme.spacing.paddingHorizontal(2),
    },
  },
}), true)
