import { variantProvider } from '@/app'
import { View, CenterWrapper, Image, Text, Breadcrumbs } from '@/components'
import { useIsMobile } from '@/utils'
import { PropsOf } from '@codeleap/common'
import { AnyFile } from '@codeleap/common/dist/tools/Form/types'

export type ImageSectionProps = {
  image: AnyFile | null
  title: string
  imageProps?: Partial<PropsOf<typeof Image>>
  showBreadcrumbs?: boolean
}

export const ImageSection = (props: ImageSectionProps) => {
  const { image, title, imageProps, showBreadcrumbs = false } = props
  const isMobile = useIsMobile()

  const Wrapper = isMobile ? View : CenterWrapper

  return (
    <Wrapper
      variants={['marginTop:auto', 'marginBottom:4'] as any}
    >
      <View
        variants={['fullWidth', 'column']}
        responsiveVariants={{
          mobile: ['column-reverse']
        }}
      >
        <View
          css={[styles.imageSectionWrapper, styles.bottomRadius]}
        >
          <Image source={image} css={[styles.image, styles.bottomRadius]} {...imageProps} />

          <View css={[styles.imageContent, styles.bottomRadius]}>
            <Text component='h1' variants={['h0', 'color:neutral1', 'marginBottom:3', 'marginTop:auto']} text={title} />
          </View>
        </View>

        {showBreadcrumbs && (
          <Breadcrumbs
            variants={['marginTop:5']}
            responsiveVariants={{
              mobile: ['marginTop:2']
            }}
          />)}
      </View>
    </Wrapper>
  )
}

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    imageSectionWrapper: {
      minHeight: 400,
      ...theme.presets.relative,
      ...theme.presets.fullWidth,
    },
    bottomRadius: {
      [theme.media.up('mobile')]: {
        borderBottomRightRadius: theme.borderRadius.medium,
        borderBottomLeftRadius: theme.borderRadius.medium,
      },
    },
    imageContent: {
      ...theme.presets.relative,
      ...theme.presets.fullWidth,
      ...theme.presets.fullHeight,
      ...theme.spacing.paddingHorizontal(2),
      backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%)`,
      zIndex: 1,
    },
    image: {
      objectFit: 'cover',
      ...theme.presets.absolute,
      ...theme.presets.fullWidth,
      ...theme.presets.fullHeight,
      zIndex: 0,
    },
    sectionWrapper: {
      [theme.media.down('mobile')]: {
        display: 'grid',
        gridTemplateColumns: '1fr',
      },
    },
  }),
  true,
)
